.block_1,
.block_2,
.block_3 {
  padding: 30px;

  background-color: rgba(150, 242, 150, 0.5);

  background-image: linear-gradient(
    to bottom,
    white,
    rgb(188, 172, 0),
    rgb(185, 185, 248)
  );
  border-radius: 10px;
}
.block_2 {
  padding-bottom: 60px;
}
.block {
  padding: 25px;
  background-color: aliceblue;
  text-align: center;
  border-radius: 20px;
  max-width: 40%;
  margin: 0 auto;
  position: relative;
  top: 10px;
  left: 0;
}
.special {
  padding: 30px;
  background-color: aliceblue;
  font-size: 18px;
  border-radius: 10px;
  min-height: 130px;
  margin: 35px 25px;
}
.check {
  max-width: 140px;
  margin-top: 50px;
  margin-left: 2%;
  margin-bottom: 20px;
}
.check a:hover {
  background-color: rgb(156, 234, 250);
}
.check a {
  padding: 15px;
  background-color: rgb(200, 242, 250);
  width: 100%;
  color: black;
  border-radius: 10px;
  cursor: pointer;
}
.swiper-pagination-fraction {
  bottom: 30px;
}
.fotoImg {
  display: block;
  margin: 0 auto;
  width: 80%;
  border-radius: 10px;
}
@media screen and (max-width: 580px) {
  .block {
    padding: 15px;
    max-width: 70%;
  }
  .fotoImg {
    display: block;
    margin: 0 auto;
    width: 100%;
    border-radius: 10px;
  }
  .block h3 {
    font-size: 16px;
  }
}
@media (max-width: 420px) {
  .block_1 .special,
  .block_2 .special,
  .block_3 .special {
    padding: 15px;
    font-size: 16px;
    min-height: 130px;
    margin: 15px 5px;
  }

  .block h3 {
    font-size: 14px;
  }
}
@media (max-width: 444px) {
  .swiper-pagination-fraction {
    bottom: 35px;
  }
  .special {
    font-size: 14px;
  }
}
@media (max-width: 314px) {
  /* .swiper-pagination-fraction {
    bottom: 20px;
  } */
  .special {
    font-size: 12px;
  }
}
