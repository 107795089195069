.main-first {
  padding-bottom: 25px;
  background-image: url(../../../img/fon.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: left;
  background-attachment: fixed;
}
/* .main-second {
  padding-bottom: 45px;
  height: auto;
  background-image: url(../../../img/fon2.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: left;
  background-attachment: fixed;
} */
