.logo2 {
  width: 120px;
  height: 120px;

  background: linear-gradient(0.25turn, #3f87a6, #ebf8e1, #f69d3c);
  border-radius: 50%;
}
.logo2 p {
  width: 65px;

  font-size: 18px;
  font-family: Cambria, Cochin, Georgia, Times, 'Times New Roman', serif;
  font-weight: 700;
}
.fname {
  margin-left: 20%;
  text-transform: uppercase;
  padding-top: 20%;
}
.logo2 .sname {
  margin-left: 35%;
}
.broom {
  position: relative;
  top: 5%;
  left: 38%;
}

@media (max-width: 460px) {
  .logo2 {
    width: 110px;
    height: 110px;
  }
  .fname {
    margin-left: 15%;
  }
  .logo2 .sname {
    margin-left: 30%;
  }
}
