.main__first-services {
  padding-top: 45px;
}
.main-services p {
  margin-top: 20px;
}

.main-title .main-title-upper span {
  /* text-transform: uppercase; */
  font-weight: 600;
  /* font-family: monospace; */
  font-size: 18px;
}
.main-title {
  margin: 10px auto 45px auto;
  background-color: azure;
  padding: 20px;
  opacity: 0.9;
  border-radius: 20px;
  max-width: 800px;
  text-align: center;
}
.main-title p {
  font-size: 24px;
  font-weight: 500;
  color: black;
  font-family: Georgia, serif;
  margin-bottom: 20px;
}
.main-title h1 {
  font-size: 28px;
  font-weight: 600;
  color: black;
  font-family: Georgia, serif;
}

.main__services {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 30px;
  margin-bottom: 20px;
}
.main-services-h1span {
  display: block;
  margin-bottom: 10px;
  text-transform: uppercase;
  padding: 15px 0;
}
.main__services-item {
  border-radius: 5px;
  padding: 15px;
  background-color: rgb(255, 255, 255);
  text-align: center;
}
.main__services-item h2 {
  text-transform: uppercase;
  padding-top: 20px;
  padding-bottom: 20px;
  color: rgb(1, 82, 175);
}

.main-services-price {
  font-size: 20px;
  font-style: italic;
  padding-bottom: 20px;
  color: rgb(3, 95, 199);
}
.main__link-details {
  cursor: pointer;
  border-radius: 10px;
  text-align: center;
  background-color: rgb(7, 80, 140);
  width: 200px;
  height: auto;
  color: white;
}
.main__link-details:hover {
  background-color: rgb(3, 112, 137);
}
.main__link-details a {
  font-size: 20px;
  display: inline-block;
  padding: 15px;
}
.main-camera-img {
  scale: 0.8;
  position: relative;
  top: 10px;
  right: 5px;
}
.main-camera-img-rigth {
  scale: 0.8;
  position: relative;
  top: 10px;
  right: -5px;
}
.main-drone-img {
  scale: 0.8;
  position: relative;
  top: 15px;
  left: -10px;
}
.main__services-img2 {
  width: 100%;
  height: 50%;
}

@media (max-width: 600px) {
  .main__first-services .main-services {
    padding: 15px;
  }

  .main-title {
    font-size: 20px;
    max-width: 500px;
  }
  .main-camera-img {
    scale: 0.7;
    position: relative;
    top: 15px;
    right: 0px;
  }
  .main-camera-img-rigth {
    scale: 0.5;
    position: relative;
    top: 15px;
    right: 0px;
  }
  .main-title h1 {
    font-size: 26px;
  }

  .main__services {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    gap: 30px;
    margin-bottom: 20px;
  }
  .main__services-item h2 {
    font-size: 20px;
  }
}
@media (max-width: 434px) {
  .main-title {
    padding: 20px;
  }
  .main-title p {
    font-size: 18px;
  }
  .main-camera-img {
    scale: 0.5;
    position: relative;
    top: 20px;
    right: 0px;
  }
  .main-title h1 {
    font-size: 18px;
    margin: 0 auto;
  }

  .main-title h3 {
    font-size: 16px;
  }
  .camera-closed {
    display: none;
  }
  .main-drone-img {
    scale: 0.6;
    position: relative;
    top: 20px;
    left: 5px;
  }
  .main-services .main-title-upper {
    font-size: 19px;
  }
}
@media (max-width: 360px) {
  .main__first-services .main-services {
    padding: 10px;
  }
  .main-title p {
    font-size: 15px;
  }
  .main-title h1 {
    font-size: 16px;
  }
  .main-services h1 {
    font-size: 16px;
  }
  .main-services .main-title-upper {
    font-size: 17px;
  }
}
